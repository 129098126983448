<template>
  <tbody v-if="visible">
    <tr
      v-for="i in rows"
      :key="`skeleton-tr-${i}`"
      class="v-data-table__empty-wrapper"
    >
      <td
        v-for="j in cols"
        :key="`skeleton-td-${j}`"
      >
        <v-skeleton-loader
          class="px-0"
          type="list-item"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 10
    },
    cols: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
